// components
const Sidebar = resolve => require(["@/components/admin/push_notification/_sidebar.vue"], resolve)
const Banner = resolve => require(["@/components/admin/push_notification/_banner.vue"], resolve)
const Announcement = resolve => require(["@/components/admin/push_notification/_announcement.vue"], resolve)

export default {
    beforeCreate: function() {
        // 使用者權限非 admin 時, 則導向至後台 '課程列表' 頁面
        if (!this.$_.isEmpty(this.$store.state.common.userInfo) &&
            this.$store.state.common.userInfo.permission !== 'admin') {
            this.$router.push('/admin/course/list/all').catch(() => {});
        }
    },
    components: {
        Sidebar,
        Banner,
        Announcement
    },
    computed: {
        componentType() {
            let validSubPages = ['banner', 'info', 'announcement', 'specific-announcement'];
            if (!validSubPages.includes(this.$route.params.subPage)) {
                this.$router.push('/not_found').catch(() => {});
            }

            return this.$route.params.subPage;
        },
        sidebarList() {
            let list = [];

            list = [
                {
                    name: '首頁橫幅',
                    url: {
                        type: 'list',
                        subPage: 'banner'
                    },
                    isShow: true
                },
                {
                    name: '資訊頁面',
                    url: {
                        type: 'list',
                        subPage: 'info'
                    },
                    isShow: false
                },
                {
                    name: '平台公告',
                    url: {
                        type: 'list',
                        subPage: 'announcement'
                    },
                    isShow: true
                },
                {
                    name: '特定公告',
                    url: {
                        type: 'list',
                        subPage: 'specific-announcement'
                    },
                    isShow: false
                }
            ]

            list.forEach(el => {
                el.isActive = el.url.subPage == this.$route.params.subPage;
                if (el.isActive) {
                    this.activePage = el;
                }
            });
            list = list.filter(item => item.isShow);

            return list;
        },
    },
    created: function() {
        this.$store.commit('common/setHeaderActiveLink', 'adminPushNotification');
    }
}
