// firebase
import firebase from 'firebase/app';
import 'firebase/auth';

export default {
    props: {
        entryTitle: {
            type: String,
            default: '註冊/登入'
        },
        entrySubmit: {
            type: String,
            default: '註冊/登入'
        },
        thirdParty: {
            type: Object,
            default: () => {
                return {
                    google: 1,
                    facebook: 1,
                    apple: 1,
                    oidc: 1,
                    openid: 1
                }
            }
        },
        oidcState: {
            type: String,
            default: `oidc|${location.pathname + location.search}`
        },
        showAccountCheckedError: {
            type: Boolean,
            default: false
        },
    },
    data: function() {
        return {
            accountType: 'phone',
            account: '',
            accountErrMsg: '',
            isCheckingAccount: false,
        }
    },
    watch: {
        account() {
            this.validateAccount();
        }
    },
    computed: {
        accountTypeTabList() {
            let tabs = [];
            tabs = [
                {
                    value: 'phone',
                    text: '手機號碼'
                },
                {
                    value: 'email',
                    text: '電子郵件'
                }
            ]

            return tabs;
        }
    },
    methods: {
        googleLogin() {
            let provider = new firebase.auth.GoogleAuthProvider();
            provider.addScope('profile');
            provider.addScope('email');
            provider.setCustomParameters({ prompt: 'select_account', });

            firebase.auth().signInWithPopup(provider).then(result => {
                // The signed-in user info.
                let user = result.user,
                    data = {
                        type : 'google',
                        name : user.providerData[0].displayName,
                        email : user.providerData[0].email,
                        uuid: user.providerData[0].uid
                    };
                this.thirdPartyLogin(data);
            });
        },
        facebookLogin() {
            let provider = new firebase.auth.FacebookAuthProvider();
            provider.addScope('email');
            provider.addScope('user_birthday');

            firebase.auth().signInWithPopup(provider).then(result => {
                // The signed-in user info.
                let user = result.user,
                    data = {
                        type : 'facebook',
                        name : user.providerData[0].displayName,
                        email : user.providerData[0].email,
                        uuid: user.providerData[0].uid
                    };
                this.thirdPartyLogin(data);
            });
        },
        appleLogin() {
            let provider = new firebase.auth.OAuthProvider('apple.com');
            provider.addScope('email');
            provider.addScope('name');
            provider.setCustomParameters({
                locale: 'tw'
            });

            firebase.auth().signInWithPopup(provider).then(result => {
                // The signed-in user info.
                let user = result.user,
                    data = {
                        type : 'apple',
                        name : user.displayName, // providerData[0].displayName 抓不到
                        email : user.providerData[0].email,
                        uuid: user.providerData[0].uid
                    };
                this.thirdPartyLogin(data);
            });
        },
        thirdPartyLogin(data) {
            let params = {
                type: data.type,
                name: data.name,
                account: data.email,
                pwdUUID: data.uuid,
                contact_email: data.email,
                device: 'web'
            }

            this.$httpRequest.post('/api/user/third_party_login', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        this.$emit('tpLoginSuccess');
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },

        oidcLogin(county) {
            let authEndpoint = county == 'tyc' ? 'https://auth.sso.edu.tw/a/tyc.sso.edu.tw/ServiceLogin?service=learnmodepremium' :
                    process.env.VUE_APP_OIDC_DOMAIN + '/oidc/v1/azp',
                clientId = '82ca149b1f28a03660614e3743d78931',
                scope = 'openid2+email+openid+profile+eduinfo',
                redirectUri = process.env.VUE_APP_ROOT_API + '/api/user/openid_login',
                state = this.oidcState,
                nonce = btoa(state),
                oidcEntryURL =  authEndpoint +
                                (authEndpoint.indexOf('?') < 0 ? '?' : '&') +
                                'response_type=code&client_id=' + clientId +
                                '&redirect_uri=' + encodeURIComponent(redirectUri) +
                                '&scope=' + scope + '&state=' + encodeURIComponent(state) +
                                '&nonce=' + nonce;

            location.href = oidcEntryURL;
        },
        openidLogin(city) {
            let state = city + '|' + location.pathname + location.search;
            let nonce = btoa(state);

            let authEndpoint = '',
                clientId = '',
                scope = '';
            if (city == 'hlc') {
                authEndpoint = 'https://hlc.sso.edu.tw/oidc/v1/azp';
                clientId = 'bce6d4206e4a809bc203523505aebdbf';
                scope = 'openid+email+profile+eduinfo+openid2+guid';
            }
            else if (city == 'hcc') {
                authEndpoint = 'https://hcc.sso.edu.tw/oidc/v1/azp';
                clientId = '4093b1df5de217d86ce3718c97de63a7';
                scope = 'openid+email+profile+eduinfo';
            }
            else if (city == 'ylc') {
                authEndpoint = 'https://ylc.sso.edu.tw/oidc/v1/azp';
                clientId = '3b867883fa48b1c06903fe891073c98b';
                scope = 'openid+email+profile+eduinfo';
            }
            else {
                alert('尚未支援該縣市');
                return;
            }
            let redirectUri = process.env.VUE_APP_ROOT_API + '/api/user/openid_login';

            let oidcEntryURL =  authEndpoint +
                                (authEndpoint.indexOf('?') < 0 ? '?' : '&') +
                                'response_type=code&client_id=' + clientId +
                                '&redirect_uri=' + encodeURIComponent(redirectUri) +
                                '&scope=' + scope + '&state=' + encodeURIComponent(state) +
                                '&nonce=' + nonce;

            location.href = oidcEntryURL;
        },
        ntpcLogin() {
            let url  =  'https://auth.ntpc.edu.tw/oauth/authorize.php' +
                        '?client_id=5f5b0ccde00a4a7aad08caabde448852' +
                        '&response_type=code' +
                        '&scope=User.Mail,User.BasicInfo,UserInfo,User.Application' +
                        '&redirect_uri=' + process.env.VUE_APP_ROOT_API + '/ntpc/callback';
            location.href = url;
        },

        changeAccountType(type) {
            this.accountType = type;
            this.account = '';
            this.accountErrMsg = '';
        },
        validateAccount() {
            this.accountErrMsg = '';

            if (!this.account) return;

            if (this.accountType == 'phone') {
                let isPhone = /^[0-9]+$/;
                if (!isPhone.test(this.account)) {
                    this.accountErrMsg = '請勿包含特殊字元';
                }
                else if (this.account.length > 10) {
                    this.accountErrMsg = '電話不能超過10個數字';
                }
                else if (this.account.length < 10) {
                    this.accountErrMsg = '電話需10個數字';
                }
            }
            else {
                let isMail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!isMail.test(this.account)) {
                    this.accountErrMsg = 'email格式錯誤';
                }
            }
        },
        generalLogin() {
            if (!this.account || this.accountErrMsg) {
                return;
            }

            this.isCheckingAccount = true;

            let params = {
                type: this.accountType,
                account: this.account,
            };

            this.$httpRequest.post('/api/user/account_check', params, false)
                .then(response => {
                    this.isCheckingAccount = false;

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        let checkedResult = {
                            isUser: result.has_user,
                            isValidated: result.is_validated,
                        };

                        if (this.showAccountCheckedError) {
                            if (checkedResult.isUser) {
                                if (checkedResult.isValidated) {
                                    this.$emit('enterGeneralEntry', this.accountType, this.account);
                                } else {
                                    this.accountErrMsg = '此帳號尚未驗證';
                                }
                            } else {
                                this.accountErrMsg = '查無此帳號';
                            }
                        } else {
                            this.$emit('enterGeneralEntry', this.accountType, this.account, checkedResult.isUser);
                        }
                    }
                    if (response.data.state == 'ERROR') {
                        this.accountErrMsg = response.data.msg;
                    }

                    let elem = document.getElementById(`${this.accountType}Input`);
                    elem && elem.focus();
                })
                .catch(error => {
                    this.isCheckingAccount = false;
                    console.error('Catched Error:', error);
                });
        }
    }
}
