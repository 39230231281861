export default {
    props: [
        'courseBundleInfo',
        'isDoingAddToGiftOrdersAction',
        'isAddingItemsToGiftOrders',
        'isDoingAddToCartAction',
        'isAddingItemsToCart',
        'isOrderingCourse'
    ]
}
