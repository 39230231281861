export default {
    beforeCreate: function() {
        this.isShowHeader = false;
        this.isShowFooter = false;
    },
    computed: {
        isShowHeader: {
            get() {
                return this.$store.state.common.isShowHeader;
            },
            set(value) {
                this.$store.commit('common/setIsShowHeader', value);
            }
        },
        isShowFooter: {
            get() {
                return this.$store.state.common.isShowFooter;
            },
            set(value) {
                this.$store.commit('common/setIsShowFooter', value);
            }
        },
        paymentPageHtml: {
            get() {
                return this.$store.state.order.paymentPageHtml;
            },
            set(value) {
                this.$store.commit('order/setPaymentPageHtml', value);
            }
        }
    },
    created: function() {
        // 若沒有金流支付頁面的 HTML 內容, 則導回首頁
        if (!this.paymentPageHtml) {
            this.$router.push('/').catch(() => {});
        }
    },
    mounted: function() {
        if (this.paymentPageHtml) {
            this.$nextTick(() => {
                document.getElementById("newebpay").submit();
            });
        }
    },
    beforeDestroy: function() {
        this.isShowHeader = true;
        this.isShowFooter = true;
        this.paymentPageHtml = '';
    }
}
