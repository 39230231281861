// directives
import ClickOutside from '@/assets/js/directives/click_outside'

export default {
    props: ['word'],
    directives: {
        ClickOutside
    },
    data: function() {
        return {
            isSearchingWord: false,  // 是否正在發送字典API查詢單字

            audioElem: new Audio(),
            playPromise: null,
            playingSoundItem: {},

            isPostingApi: {
                updateMarkedWord: false,  // 收藏/取消收藏單字
            }
        }
    },
    computed: {
        articleUtil() {
            return this.$store.state.article.articleUtil;
        },
        activeSearchedWord: {
            get() {
                return this.$store.state.article.activeSearchedWord;
            },
            set(value) {
                this.$store.commit('article/setActiveSearchedWord', value);
            }
        },
        dictionaryResult: {
            get() {
                return this.$store.state.article.dictionaryResult;
            },
            set(value) {
                this.$store.commit('article/setDictionaryResult', value);
            }
        },
        isShowDictBriefResult: {
            get() {
                return this.$store.state.article.isShowDictBriefResult;
            },
            set(value) {
                this.$store.commit('article/setIsShowDictBriefResult', value);
            }
        },
        isShowDictResultDialogue: {
            get() {
                return this.$store.state.article.isShowDictResultDialogue;
            },
            set(value) {
                this.$store.commit('article/setIsShowDictResultDialogue', value);
            }
        },
        articleContentHeight() {
            return this.$store.state.article.articleContentHeight;
        },
        userMarkedWords: {
            get() {
                return this.$store.state.article.userMarkedWords;
            },
            set(value) {
                this.$store.commit('article/setUserMarkedWords', value);
            }
        },
        isMarkWordSuccess: {
            get() {
                return this.$store.state.article.isMarkWordSuccess;
            },
            set(value) {
                this.$store.commit('article/setIsMarkWordSuccess', value);
            }
        },
        articleLevelWords() {
            return this.$store.state.article.articleLevelWords;
        }
    },
    beforeDestroy: function() {
        this.pauseAudio();  // 離開頁面需停止音檔播放
    },
    methods: {
        setWordMarkStyle(item) {
            let styles = [];

            // 使用者收藏/取消收藏單字
            if (this.$_.includes(this.userMarkedWords, this.articleUtil.handleWord(item.word, true))) {
                item.isMarked = true;
                styles.push('is-marked');
            } else {
                item.isMarked = false;
            }
            // 字典查詢單字顯示結果時
            if (!this.isSearchingWord &&
                item.id === this.activeSearchedWord.id &&
                !this.$_.isEmpty(this.dictionaryResult) &&
                this.articleUtil.handleWord(item.word, true) === this.dictionaryResult.word) {
                styles.push('is-searched');
            }
            // 符合選擇的單字程度的單字
            if (this.$_.includes(this.articleLevelWords, this.articleUtil.handleWord(item.word, true))) {
                styles.push('is-level-word');
            }

            return styles.join(' ');
        },
        showDictIcon(item) {
            if (!this.articleUtil.handleWord(item.word, true) ||
                (this.isSearchingWord && (item.id === this.activeSearchedWord.id))) {
                return;
            }

            this.activeSearchedWord = item;
            this.isSearchingWord = false;
            this.isShowDictBriefResult = false;
            this.dictionaryResult = {};
        },
        hideDictIcon(event) {
            if (this.$_.isNull(this.activeSearchedWord.id)) {
                return;
            }

            // 以下判斷是避免因為點擊下列白名單區塊而初始查詢單字使用的變數們, 導致隱藏字典查詢結果框
            // 1. 點擊查詢結果框內的 DOM
            // 2. 點擊更多釋義彈窗內的 DOM
            // 3. 點擊筆記區塊內的 DOM
            let wordWrapperDOM = document.getElementById(`wordWrapper${this.activeSearchedWord.id}`);
            let dictDialogueDOM = document.getElementById('dictResultDialogue');
            let notesDOM = document.getElementById('articleNotes');

            if ((wordWrapperDOM && wordWrapperDOM.contains(event.target)) ||
                (dictDialogueDOM && dictDialogueDOM.contains(event.target)) ||
                (notesDOM && notesDOM.contains(event.target))) {
                return;
            }

            // 若在文章閱讀頁面已查詢出單字結果, 則點擊 '個人筆記' 按鈕時不隱藏字典查詢結果框
            if (event.target.parentNode && event.target.parentNode.id == 'openNoteBtn') {
                this.isShowDictBriefResult = true;
                return;
            }

            this.activeSearchedWord = { id: null };
            this.isSearchingWord = false;
            this.isShowDictBriefResult = false;
            this.dictionaryResult = {};
        },
        searchWord() {
            if (this.isSearchingWord) {
                return;
            }

            this.isSearchingWord = true;
            this.$store.dispatch('article/searchWord')
                .then(() => {
                    // 如果 (單字 DOM Top + 400px) 超過 文章內容高度, 則將簡略釋義小框顯示在單字上方
                    let currentWordDOMTop = Math.floor($(`#wordWrapper${this.activeSearchedWord.id}`).offset().top);
                    let offset = 400;
                    this.activeSearchedWord.isBriefResultPositionTop = currentWordDOMTop + offset >= this.articleContentHeight;

                    this.isSearchingWord = false;
                    this.isShowDictBriefResult = true;
                })
                .catch((error) => {
                    console.log('catched error: ' + error);
                });
        },
        playPronunciationAudio(item) {
            if (item.isPlayingSound) {
                return;
            }

            if (!item.pronunciationAudioFile) {
                this.$store.dispatch('common/setAlert', { status: 'danger', msg: '無效的音檔來源' });
                return;
            }

            if (item != this.playingSoundItem) {
                this.playingSoundItem.isPlayingSound = false;
            }
            this.playingSoundItem = item;

            this.audioElem.src = item.pronunciationAudioFile;
            if (this.audioElem.paused) {
                this.playPromise = this.audioElem.play();

                // 判斷音檔來源如果無效, 需提示為無效的音檔來源
                if (this.playPromise && this.playPromise !== undefined) {
                    this.playPromise
                        .catch((error) => {
                            if (error.name === 'NotSupportedError') {
                                this.$store.dispatch('common/setAlert', { status: 'danger', msg: '無效的音檔來源' });
                            }
                            console.log('catched error: ' + error);
                        });
                }
            }
            this.audioElem.onplaying = () => {
                item.isPlayingSound = true;
            };
            this.audioElem.onended = () => {
                item.isPlayingSound = false;
            };
        },
        pauseAudio() {
            if (this.playPromise && this.playPromise !== undefined) {
                this.playPromise
                    .then(() => {
                        this.audioElem.pause();
                        this.playingSoundItem.isPlayingSound = false;
                    })
                    .catch((error) => {
                        console.log('catched error: ' + error);
                    });
            }
        },
        async updateMarkedWord() {
            if (this.isPostingApi.updateMarkedWord) {
                return;
            }

            let payload = {
                articleId: this.$route.params.id
            }

            this.isPostingApi.updateMarkedWord = true;
            await this.$store.dispatch('article/updateMarkedWord', payload);
            this.isPostingApi.updateMarkedWord = false;
        },
        showDictResultDialogue() {
            this.isShowDictBriefResult = false;
            this.isShowDictResultDialogue = true;
            $('#dictResultDialogue').modal('show');
        },
    }
}
