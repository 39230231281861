import InfiniteScroll from 'vue-infinite-scroll'

// common
const ArticleCard = resolve => require(["@/components/common/card/article.vue"], resolve)

export default {
    props: {
        allArticleIds: {
            required: true,
            type: Array
        },
        rowMaxCount: {
            required: true,
            type: Number
        },
        iconTip: {
            required: true,
            type: Boolean
        },
        publishTime: {
            required: true,
            type: Boolean
        },
        categoryTag: {
            required: true,
            type: Boolean
        }
    },
    directives: {
        InfiniteScroll
    },
    components: {
        ArticleCard
    },
    data: function() {
        return {
            articleList: [],
            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢
        }
    },
    created: function() {
        if (this.allArticleIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        async loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                await this.getArticleList();
                this.busy = false;
            }
        },
        getArticleList() {
            return new Promise((resolve, reject) => {
                let articleIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempArticleId = this.allArticleIds[this.loadingIndex];
                    if (!tempArticleId) {
                        this.isGetAllList = true;
                        break;
                    }
                    articleIds.push(tempArticleId);
                    this.loadingIndex++;
                }

                if (!articleIds.length) {
                    resolve();
                    return;
                }

                let params = {
                    articleIds: articleIds
                };

                this.$httpRequest.post('/api/course/get_article_list_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                for (let id of articleIds) {
                                    let tempArticle = result[id];
                                    if (tempArticle) {
                                        this.articleList.push({
                                            id: tempArticle.id,
                                            name: tempArticle.article_name,
                                            cover: tempArticle.file_path,
                                            category: tempArticle.category_name,
                                            difficultyLevel: tempArticle.difficulty_level,
                                            isRead: tempArticle.is_read,
                                            hasNote: tempArticle.is_note,
                                            practiceTypes: tempArticle.practice_types,
                                            issueNoDate: tempArticle.issue_no_date ? this.$util.unixTimestampToDatetime(tempArticle.issue_no_date, this.$util.getBrowserCurrentTimeZone(), 'date') : ''
                                        });
                                    }
                                }

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
    }
}
