export default {
    props: {
        accountType: {
            required: true,
            type: String
        },
        account: {
            required: true,
            type: String
        },
        verifyType: {
            required: true,
            type: String,
            validator: value => {
                return ['resetPWD', 'auth'].includes(value)
            }
        }
    },
    data: function() {
        return {
            codes: {},
            codeElems: [],
            maxCodeLength: 6,
            verifyFail: false,
            timerIntervalId: '',
            timer: 0,

            isPostingApi: {
                verifyAccount: false,  // 檢查驗證碼
                sendVerificationCode: false,  // 傳送驗證碼
            }
        }
    },
    created: function() {
        if (this.verifyType === 'resetPWD') {
            this.sendVerificationCode();
        }

        for (let i = 0; i < this.maxCodeLength; i++) {
            this.$set(this.codes, i+1, '');
        }
    },
    mounted: function() {
        this.$nextTick(() => {
            this.codeElems = document.querySelectorAll('input[type=tel]');
            if (this.codeElems[0]) {
                this.codeElems[0].focus();
                this.codeElems[0].addEventListener('paste', this.handleCodePaste);
            }
        });
    },
    methods: {
        handleCodeInput(event) {
            const input = event.target;
            const nextInput = input.nextElementSibling;

            if (nextInput && input.value) {
                nextInput.focus();

                if (nextInput.value) {
                    nextInput.select();
                }
            }
        },
        handleCodePaste(event) {
            event.preventDefault();

            const pastes = event.clipboardData.getData('text');

            for (let i = 0; i < this.maxCodeLength; i++) {
                this.$set(this.codes, i+1, pastes[i] || '');
            }
        },
        handleCodeFocus(event) {
            setTimeout(() => event.target.select(), 0);
        },
        handleCodeKeydown(event) {
            let _handleBackspace = (event) => {
                const input = event.target;

                if (input.value) {
                    this.$set(this.codes, input.id, '');
                    return;
                }

                const previousInput = input.previousElementSibling;

                if (previousInput) {
                    previousInput.focus();
                }
            }

            let _handleArrowLeft = (event) => {
                const previousInput = event.target.previousElementSibling;

                if (previousInput) {
                    previousInput.focus();
                }
            }

            let _handleArrowRight = (event) => {
                const nextInput = event.target.nextElementSibling;

                if (nextInput) {
                    nextInput.focus();
                }
            }

            switch (event.keyCode) {
                case 8:  // backspace
                    _handleBackspace(event);
                    break;
                case 37:  // arrowLeft
                    _handleArrowLeft(event);
                    break;
                case 39:  // arrowRight
                    _handleArrowRight(event);
                    break;
            }
        },

        sendVerificationCode() {
            if (this.timer || this.isPostingApi.sendVerificationCode) {
                return;
            }

            let params = {
                type: this.accountType,
                account: this.account,
                authType: this.verifyType
            };

            this.isPostingApi.sendVerificationCode = true;

            this.$httpRequest.post('/api/user/re_sent_auth_code', params)
                .then(response => {
                    this.isPostingApi.sendVerificationCode = false;

                    if (response.data.state == 'OK') {
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                    if (response.data.state == 'ERROR') {
                        this.timer = +response.data.sec;
                        this.timerIntervalId = setInterval(() => {
                            this.timer -= 1;

                            if (!this.timer) {
                                clearInterval(this.timerIntervalId);
                            }
                        }, 1000);
                    }
                })
                .catch(error => {
                    this.isPostingApi.sendVerificationCode = false;
                    console.error('Catched Error:', error);
                });
        },
        verifyAccount() {
            let code = Object.values(this.codes).join('');

            if (code.length !== this.maxCodeLength || this.isPostingApi.verifyAccount) {
                return;
            }

            this.verifyFail = false;

            let params = {
                type: this.accountType,
                account: this.account,
                authType: this.verifyType,
                authCode: code,
                device: 'web'
            };

            this.isPostingApi.verifyAccount = true;

            this.$httpRequest.post('/api/user/auth_account', params)
                .then(response => {
                    this.isPostingApi.verifyAccount = false;

                    if (response.data.state == 'OK') {
                        switch (this.verifyType) {
                            // 完成註冊流程
                            case 'auth':
                                this.$emit('registerSuccess');
                                break;
                            // 驗證帳號成功, 導向重新設定密碼頁面
                            case 'resetPWD':
                                this.$emit('setNewPwd', params.authCode);
                                break;
                        }
                    }
                    if (response.data.state == 'ERROR') {
                        this.verifyFail = true;
                    }
                })
                .catch(error => {
                    this.isPostingApi.verifyAccount = false;
                    console.error('Catched Error:', error);
                });
        }
    }
}
