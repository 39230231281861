var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],staticClass:"row no-gutters article-list",attrs:{"infinite-scroll-disabled":"busy","infinite-scroll-distance":"10","infinite-scroll-immediate-check":"false"}},[_vm._l((_vm.articleList),function(article){return _c('div',{key:article.index,staticClass:"col-12 article-item",class:[`col-md-${12 / +_vm.rowMaxCount}`]},[_c('router-link',{attrs:{"to":`/article/${article.id}`}},[_c('ArticleCard',{attrs:{"info":{
                    name: article.name,
                    cover: article.cover,
                    difficultyLevel: article.difficultyLevel,
                    category: _vm.categoryTag ? article.category : '',
                    isRead: article.isRead,
                    hasNote: article.hasNote,
                    practiceTypes: article.practiceTypes,
                    issueNoDate: article.issueNoDate ? article.issueNoDate : ''
                },"mobileStyle":'list',"iconTip":_vm.iconTip}})],1)],1)}),(_vm.busy)?_c('div',{staticClass:"col-12 m-b-30"},[_c('DotLoading')],1):_vm._e(),(!_vm.busy && _vm.isGetAllList && !_vm.articleList.length)?_c('span',{staticClass:"col-12 load-more-tip"},[_vm._v("尚無內容")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }