// common
import Dialogue from "@/components/common/dialogue.vue"

export default {
    components: {
        Dialogue
    },
    computed: {
        isGiftOrder() {
            return this.$store.state.user.isGiftOrder;
        },
        activeUnsubscribeOrder: {
            get() {
                return this.$store.state.user.activeUnsubscribeOrder;
            },
            set(value) {
                this.$store.commit('user/setActiveUnsubscribeOrder', value);
            }
        }
    },
    methods: {
        unsubscribe() {
            let params = {
                orderNo: this.activeUnsubscribeOrder.orderNo
            }

            this.$httpRequest.get('/api/order/unsubscribe', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.$store.dispatch('common/setAlert', { status: 'success', msg: response.data.msg });

                            // 更新訂單狀態
                            this.activeUnsubscribeOrder.isUnsubscribed = true;

                            $('#unsubscribePromptDialogue').modal('hide');
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        }
    }
}
