export default {
    computed: {
        alertStyle() {
            switch (this.$store.state.common.alert.status) {
                case 'success':
                    return 'alert-success';
                case 'danger':
                    return 'alert-danger';
                case 'info':
                    return 'alert-info';
            }
            return 'alert-info';
        }
    },
}
