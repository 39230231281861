export default {
    props: ['allBannerIds'],
    data: function() {
        return {
            bannerList: [],

            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 50,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢
        }
    },
    watch: {
        newUnpublishedItem(newValue) {
            if (!this.$_.isEmpty(newValue)) {
                this.bannerList = [newValue].concat(this.bannerList);
                this.newUnpublishedItem = {};
            }
        },
        deletedUnpublishedId(newValue) {
            if (!this.$_.isNull(newValue)) {
                this.bannerList = this.$_.remove(this.bannerList, el => {
                    return el.id !== newValue;
                });
                this.deletedUnpublishedId = null;
            }
        },
        isDeleteAllBanners(newValue) {
            if (newValue) {
                this.bannerList = [];
                this.isDeleteAllBanners = false;
            }
        }
    },
    computed: {
        newUnpublishedItem: {
            get() {
                return this.$store.state.adminPnBanner.newUnpublishedItem;
            },
            set(value) {
                this.$store.commit('adminPnBanner/setNewUnpublishedItem', value);
            }
        },
        deletedUnpublishedId: {
            get() {
                return this.$store.state.adminPnBanner.deletedUnpublishedId;
            },
            set(value) {
                this.$store.commit('adminPnBanner/setDeletedUnpublishedId', value);
            }
        },
        isDeleteAllBanners: {
            get() {
                return this.$store.state.adminPnBanner.isDeleteAllBanners;
            },
            set(value) {
                this.$store.commit('adminPnBanner/setIsDeleteAllBanners', value);
            }
        }
    },
    created: function() {
        if (this.allBannerIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        async loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                await this.getBannerList();
                this.busy = false;
            }
        },
        async getBannerList() {
            let bannerIds = [];

            for (let i = 0; i < this.loadCountPerPage; i++) {
                let tempBannerId = this.allBannerIds[this.loadingIndex];
                if (!tempBannerId) {
                    this.isGetAllList = true;
                    break;
                }
                bannerIds.push(tempBannerId);
                this.loadingIndex++;
            }

            if (bannerIds.length == 0) {
                return;
            }

            let newBannerList = await this.$parent.getBannerInfoList(bannerIds);
            this.bannerList = this.bannerList.concat(newBannerList);
        }
    }
}
