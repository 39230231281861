// common
import Dialogue from "@/components/common/dialogue.vue"

export default {
    props: ['courseBasicInfo'],
    components: {
        Dialogue
    },
    data: function() {
        return {
            activeTab: 'price',

            courseInfo: null,
            courseSalesInfo: null,
            oldCourseSalesInfo: null,

            inputPriceErrorMsg: '輸入金額須為大於0的半形整數',

            newSalesPlan: {
                name: null,
                unit: null,
                price: null
            },
            isNewSalesPlanNameRepeat: false,
            inputSalesPlanNameRepeatMsg: '方案名稱不可重複，請重新命名',
            inputSalesPlanExceedLengthMsg: '方案名稱不得超過4字',
            inputUnitErrorMsg: '輸入單位須為範圍1~12的半形整數',
            selectedSalesPlanIndex: null,
            deletedPlanIds: [],

            activeShowingPriceIdNameInfo: {},

            isSetDataReady: {
                getCourseSalesInfo: false  // 取得課程銷售資訊
            }
        }
    },
    computed: {
        editSalesTabList() {
            let tabs = [];
            tabs = [
                {
                    value: 'price',
                    text: '銷售方案',
                    isShow: true
                }
            ]
            tabs = tabs.filter(tab => tab.isShow);
            return tabs;
        },
        salesPlanTableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "id_name",
                    itemText: "方案ID",
                    ownClass: "sales-plan-id-name",
                    isShow: true
                },
                {
                    itemName: "name",
                    itemText: "名稱",
                    ownClass: "sales-plan-name",
                    isShow: true
                },
                {
                    itemName: "unit",
                    itemText: "單位",
                    ownClass: "sales-plan-unit",
                    isShow: true
                },
                {
                    itemName: "price",
                    itemText: "單價",
                    ownClass: "sales-plan-price",
                    isShow: true
                },
                {
                    itemName: "total_price",
                    itemText: "總價",
                    ownClass: "sales-plan-total-price",
                    isShow: true
                },
                {
                    itemName: "advanced",
                    itemText: "進階",
                    ownClass: "sales-plan-advanced",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
        purchaseTypeOptList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '訂閱'
                },
                {
                    value: '1',
                    text: '購買'
                }
            ]
            return opts;
        },
        updateFrequencyOptList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '每日'
                },
                {
                    value: '1',
                    text: '每周'
                },
                {
                    value: '2',
                    text: '每月'
                }
            ]
            return opts;
        },
        englishDifficultyLevelOptList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '初級'
                },
                {
                    value: '1',
                    text: '中級'
                },
                {
                    value: '2',
                    text: '中高級'
                },
                {
                    value: '3',
                    text: '高級'
                },
                {
                    value: '4',
                    text: '優級'
                }
            ]
            return opts;
        },
        priceUnitText() {
            return this.courseBasicInfo.purchaseType == '0' ? '元/月' : '元';
        },
        isPriceInputValid() {
            let regExp = /^\d+$/;  // 半形整數
            // 不是半形整數或值為0的狀況則為 false
            return value => !value || (value > 0) && regExp.test(value);  // 排除 value 為 null 的情況
        },
        currentSalesPlanNames() {
            let ary = [];
            this.courseSalesInfo.subscribePlans.forEach(el => ary.push(el.name));
            return ary;
        },
        isPlanNameInputLengthValid() {
            // 內容超過4字則為 false
            return value => !value || value.length <= 4;
        },
        isUnitInputValid() {
            let regExp = /^\d+$/;  // 半形整數
            // 不是半形整數或值不在範圍1~12的狀況則為 false
            return value => !value || (value > 0 && value < 13) && regExp.test(value);  // 排除 value 為 null 的情況
        },
        isSomeInputError() {
            return !this.isPriceInputValid(this.courseSalesInfo.priceInfo.price);
        },
        updateCourseData() {
            if (!this.isSetDataReady.getCourseSalesInfo) {
                return;
            }

            let updateData = {};

            // 銷售方案 - 課程定價
            let priceEdited = {};
            priceEdited = this.$_.omitBy(this.courseSalesInfo.priceInfo, (v, k) => {
                return this.oldCourseSalesInfo.priceInfo[k] == v;
            });

            if (!this.$_.isEmpty(priceEdited)) {
                updateData.price = this.courseSalesInfo.priceInfo.price;
            }

            // 銷售方案 - 銷售方案
            // 判斷方案列表是否有做新增或刪除項目
            let tempPlanIds = [], oldPlanIds = [];
            this.courseSalesInfo.subscribePlans.forEach(el => tempPlanIds.push(el.id));
            this.oldCourseSalesInfo.subscribePlans.forEach(el => oldPlanIds.push(el.id));
            let diffPlanIds = this.$_.difference(tempPlanIds, oldPlanIds);

            updateData.subscribeInfo = {};
            // 有新增的方案
            if (diffPlanIds.length) {
                let addPlans = [];
                this.courseSalesInfo.subscribePlans.forEach(el => {
                    if (!el.id) {
                        addPlans.push(el);
                    }
                });
                if (addPlans.length) {
                    updateData.subscribeInfo.add = addPlans;
                }
            }
            // 有刪除的方案
            if (this.deletedPlanIds.length) {
                updateData.subscribeInfo.deleteIds = this.deletedPlanIds;
            }
            // 如果這個key為空物件, 則刪除此key
            if (this.$_.isEmpty(updateData.subscribeInfo)) {
                updateData = this.$_.omit(updateData, ['subscribeInfo']);
            }

            updateData.saveType = 'sales';  // 指定儲存類型

            return updateData;
        },
        isEditable() {
            // 是否可以編輯課程定價或銷售方案(訂閱制), 條件如下:
            // 1. 課程狀態為"編輯中"
            // 2. 使用者權限為 admin 時, "待審核"狀態還可編輯以上項目
            return this.courseBasicInfo.state == '0' ||
                    (this.$store.state.common.userInfo.permission == 'admin' && this.courseBasicInfo.state == '1');
        }
    },
    created: function() {
        this.initialize();
    },
    mounted: function() {
        // 彈窗打開後直接 focus 輸入框
        $('#addSalesPlanDialogue').on('shown.bs.modal', () => {  // 新增方案
            $('#addSalesPlanNameInput').focus();
        });

        // 彈窗打開有按下 enter 按鍵後做對應動作
        $("#addSalesPlanDialogue").keypress(event => {  // 新增方案
            let keycode = (event.keyCode ? event.keyCode : event.which);
            if (keycode == '13') {
                this.addSalesPlan();
            }
        });
    },
    beforeDestroy: function() {
        this.$store.commit('adminCourse/setIsInputFormatError', false);
    },
    methods: {
        initialize() {
            let refreshCourseSalesInfo = () => {
                this.$parent.isFinishUpdatingData = false;
                this.isSetDataReady.getCourseSalesInfo = false;
                this.oldCourseSalesInfo = null;
                this.deletedPlanIds = [];

                this.getCourseSalesInfo()
                    .then(() => {
                        this.isSetDataReady.getCourseSalesInfo = true;

                        // 監聽編輯內容是否更動
                        this.$watch('updateCourseData', newValue => {
                            this.$store.commit('adminCourse/setUpdateCourseData', newValue);
                            this.$store.commit('adminCourse/setIsShowNotSaveCoursePrompt', !this.$_.isEmpty(this.$_.omit(newValue, ['saveType'])));
                        });
                        // 輸入框是否輸入格式錯誤
                        this.$watch('isSomeInputError', newValue => {
                            this.$store.commit('adminCourse/setIsInputFormatError', newValue);
                        });
                    })
                    .catch(error => console.log('catched error: ' + error));
            }

            refreshCourseSalesInfo();

            // 當成功更新課程銷售資訊後, 則重新取得銷售資訊
            this.$watch('$parent.isFinishUpdatingData', newValue => {
                if (newValue) {
                    refreshCourseSalesInfo();
                }
            });
        },

        getCourseSalesInfo() {
            if (!this.$route.params.id) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    courseId: this.$route.params.id
                }

                this.$httpRequest.get('/admin_api/course/get_course_price', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                this.courseInfo = {
                                    name: result.course_info.name,
                                    provider: result.course_info.provider,
                                    cover: {
                                        path: null
                                    }
                                }
                                // 課程封面
                                if (!this.$_.isEmpty(result.course_info.cover)) {
                                    this.courseInfo.cover.path = result.course_info.cover.file_name;
                                }

                                this.courseSalesInfo = {
                                    // 課程定價
                                    priceInfo: {
                                        idName: result.price.course_price.id_name,
                                        price: result.price.course_price.price
                                    },
                                    // 銷售方案
                                    subscribePlans: []
                                }

                                // 購買類型為訂閱時, 才有"銷售方案" (0: 訂閱, 1: 購買)
                                if (this.courseBasicInfo.purchaseType == '0') {
                                    result.price.course_subscribe_price.forEach(el => {
                                        this.courseSalesInfo.subscribePlans.push({
                                            id: el.csp_id,
                                            idName: el.id_name,
                                            name: el.name,
                                            unit: el.unit,
                                            price: el.price
                                        })
                                    });
                                }

                                // 暫存原本的銷售資料, 以用來判斷是否enable儲存按鈕
                                this.oldCourseSalesInfo = this.$_.cloneDeep(this.courseSalesInfo);

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        showAddSalesPlanDialogue() {
            if (this.courseSalesInfo.subscribePlans.length >= 20 || !this.isEditable) {
                return;
            }

            this.newSalesPlan = this.$_.mapValues(this.newSalesPlan, () => null);
            this.isNewSalesPlanNameRepeat = false;
            $('#addSalesPlanDialogue').modal('show');
        },
        addSalesPlan() {
            if (this.$_.some(this.newSalesPlan, this.$_.isNull) || this.isNewSalesPlanNameRepeat || !this.isUnitInputValid(this.newSalesPlan.unit) || !this.isPriceInputValid(this.newSalesPlan.price)) {
                return;
            }

            let newItem = this.$_.cloneDeep(this.newSalesPlan);
            newItem.id = null;
            this.courseSalesInfo.subscribePlans.push(newItem);
            $('#addSalesPlanDialogue').modal('hide');
            // 因為新增的項目在方案列表最後一個, 因此拉動捲軸至列表最下方
            this.$nextTick(() => $('#salesPlanTableList').scrollTop($('#salesPlanTableList')[0].scrollHeight));
        },
        showDeleteSalesPlanDialogue(index) {
            if (!this.isEditable) {
                return;
            }

            this.selectedSalesPlanIndex = index;
            $('#deleteSalesPlanDialogue').modal('show');
        },
        deleteSalesPlan() {
            this.courseSalesInfo.subscribePlans = this.$_.remove(this.courseSalesInfo.subscribePlans, (el, i) => {
                if (i === this.selectedSalesPlanIndex && el.id) {  // 欲刪除的方案為原本存在的方案
                    this.deletedPlanIds.push(el.id);
                }
                return i !== this.selectedSalesPlanIndex;
            });
            $('#deleteSalesPlanDialogue').modal('hide');
        },
        showPriceIdNameDialogue(priceName, idName) {
            this.activeShowingPriceIdNameInfo = {
                priceName: priceName,
                idName: idName
            }

            $('#priceIdNameDialogue').modal('show');
        }
    }
}
