// common
const Dialogue = resolve => require(["@/components/common/dialogue_client.vue"], resolve)

export default {
    components: {
        Dialogue,
    },
    computed: {
        isPracticing: {
            get() {
                return this.$store.state.article.isPracticing;
            },
            set(value) {
                this.$store.commit('article/setIsPracticing', value);
            },
        },
        practicingCategory() {
            return this.$store.state.article.practicingCategory;
        },
        hasTempHandin() {
            return ['4', '5'].includes(this.practicingCategory.scoreType);
        },
    },
    mounted: function() {
        // 關閉離開練習前系統不自動儲存提示彈窗後
        $('#notSavePracticeHistoryPromptDialogue').on('hidden.bs.modal', () => {
            if (this.isPracticing) {
                let path = `/${this.$route.params.articleIndex}/${this.$route.params.id}/practicing`;
                if (this.$route.params.version) {
                    path = `/${this.$route.params.articleIndex}/${this.$route.params.id}/practicing/${this.$route.params.version}`;
                }
                this.$router.replace(path).catch(() => {});
            }
        });
    },
    methods: {
        continuePracticing(isContinue) {
            if (!isContinue) {
                this.isPracticing = false;
            }

            $('#notSavePracticeHistoryPromptDialogue').modal('hide');
        },
    },
}
