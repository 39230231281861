// components
const OrderItemList = resolve => require(["@/components/order/index/_order_item_list.vue"], resolve)
const OrderInfo = resolve => require(["@/components/order/index/_order_info.vue"], resolve)
const SelectCouponDialogue = resolve => require(["@/components/order/index/_select_coupon_dialogue.vue"], resolve)

export default {
    components: {
        OrderItemList,
        OrderInfo,
        SelectCouponDialogue
    },
    data: function() {
        return {
            isSetDataReady: {
                getItems: false  // 取得商品列表
            }
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        orderUtil() {
            return this.$store.state.order.orderUtil;
        },
        currentOrderItem: {
            get() {
                return this.$store.state.order.currentOrderItem;
            },
            set(value) {
                this.$store.commit('order/setCurrentOrderItem', value);
            }
        },
        orderItemsInfo: {
            get() {
                return this.$store.state.order.orderItemsInfo;
            },
            set(value) {
                this.$store.commit('order/setOrderItemsInfo', value);
            }
        },
        orderItemCourseIds: {
            get() {
                return this.$store.state.order.orderItemCourseIds;
            },
            set(value) {
                this.$store.commit('order/setOrderItemCourseIds', value);
            }
        },
        confirmSelectedCoupon() {
            return this.$store.state.order.confirmSelectedCoupon;
        },
        checkCouponCourseIds() {
            if (this.$_.isEmpty(this.currentOrderItem) || !this.isSetDataReady.getItems) {
                return [];
            }

            return [this.currentOrderItem.courseInfo.id];
        },
        isDisableUseCoupon() {
            if (this.$_.isEmpty(this.currentOrderItem) || !this.isSetDataReady.getItems) {
                return false;
            }

            // 選擇訂閱方案不得使用優惠券
            return this.currentOrderItem.selectedPrice.type === 'plan';
        },
        originalTotalPrice() {
            if (this.$_.isEmpty(this.currentOrderItem) || !this.isSetDataReady.getItems) {
                return 0;
            }

            let result = {
                price: +this.currentOrderItem.selectedPrice.unit * +this.currentOrderItem.selectedPrice.price,
                noBundleTotal: 0 // 雖然訂閱制沒有合購，不過還是需要補初始值
            };

            return result;
        },
        orderTotalPrice() {
            let totalPrice = this.originalTotalPrice.price,
                discount = 0; // 折抵金額

            // 使用優惠券
            if (!this.$_.isEmpty(this.confirmSelectedCoupon)) {
                // 折扣日期(體驗券)
                if (this.confirmSelectedCoupon.discountType === '0') {
                    totalPrice = 0;
                }
                // 折價金額
                if (this.confirmSelectedCoupon.discountType === '1') {
                    totalPrice = totalPrice - this.confirmSelectedCoupon.discount;
                }
                // 折扣金額
                if (this.confirmSelectedCoupon.discountType == '2') {
                    let discountPrice = Math.round(totalPrice * (this.confirmSelectedCoupon.discount / 100));
                    discount = totalPrice - discountPrice;
                    totalPrice = discountPrice;
                }
                // 若折價後總計已小於 0 時, 則以 $0 顯示
                if (totalPrice < 0) {
                    totalPrice = 0;
                }
            }

            let result = {
                price: totalPrice,
                discount: discount
            }

            return result;
        }
    },
    created: function() {
        this.getData();
    },
    methods: {
        async getData() {
            await this.addOrderItemToList();
            this.isSetDataReady.getItems = true;
        },
        addOrderItemToList() {
            return new Promise((resolve, reject) => {
                if (this.$_.isEmpty(this.currentOrderItem)) {
                    reject("order/orders.js: 'addOrderItemToList' error");
                    return;
                }

                this.orderItemsInfo[this.currentOrderItem.courseInfo.id] = this.orderUtil.setOrderItemInfo(this.currentOrderItem.courseInfo, this.currentOrderItem.selectedPrice);
                this.orderItemCourseIds = [this.currentOrderItem.courseInfo.id];

                if (this.orderItemCourseIds.length === 1) {
                    resolve();
                } else {
                    reject("order/orders.js: 'addOrderItemToList' error");
                }
            });
        },
        deleteOrdersItem() {
            let redirectCourseId = localStorage.getItem('fromCourse');
            if (redirectCourseId && redirectCourseId !== '0') {
                localStorage.removeItem('fromCourse');
                this.currentOrderItem = {};  // 刪除目前訂單商品
                this.$router.push(`/course/${redirectCourseId}`).catch(() => {});
            } else {
                this.$router.push('/').catch(() => {});
            }
        }
    }
}
