import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/scss/index.scss'
import axios from 'axios'

let axiosInstance = axios.create();  // 建立實體

// components

export default {
    components: {
        DatePicker
    },
    data: function() {
        return {
            publishStart : "",
            publishEnd : "",
            isGettingExcel : false
        }
    },
    computed: {

    },
    created: function() {
        this.get_now_time();
    },
    methods: {
        disableStartDate(date) {
            // disable小於今天以前的日期與大於有設定的結束時間
            var now = new Date();
            var endDate = new Date(this.publishEnd).setHours(0,0,0,0);
            return (now >= endDate) ?  (date > endDate) : (date > now);
        },
        disableEndDate(date) {
            return date > new Date();
            // disable小於有設定的開始時間
            // return this.announcementInfo.publishStart && date.setHours(0, 0, 0, 0) < new Date(this.announcementInfo.publishStart).setHours(0, 0, 0, 0);
        },
        get_now_time(){
            var now = new Date();
            var now_month = ((''+(now.getMonth()+1)).length < 2) ? ('0' + (now.getMonth()+1)) : (now.getMonth()+1);
            var now_day = ( (''+now.getDate()).length < 2) ? ('0' + now.getDate()) : now.getDate();

            this.publishStart = now.getFullYear() + '/' + now_month + '/' + now_day;
            this.publishEnd = now.getFullYear() + '/' + now_month + '/' + now_day;
        },
        getExcel(){
            var tmpStart = new Date(this.publishStart).setHours(0,0,0,0);
            var tmpEnd = new Date(this.publishEnd).setHours(0,0,0,0);
            if( tmpEnd <= tmpStart ){
                console.log('時間設定有誤');
                return;
            }

            this.isGettingExcel = true
            var params = {
                start : new Date(this.publishStart).setHours(0,0,0,0),
                end : new Date(this.publishEnd).setHours(0,0,0,0)
            }

            axiosInstance.get('https://060rp7edcd.execute-api.ap-northeast-1.amazonaws.com/prod' , {params})
            .then(
                response => {
                    this.isGettingExcel = false
                    console.log(response.data);
                    this.$util.downloadFile(response.data , 'report.xlsx');
                },
                error => {
                    console.log(error);
                }
            )
            .catch(error => {
                console.log('catched error:', error);
            });
        }
    }
}
