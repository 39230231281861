import Sidebar from "@/components/admin/order/_sidebar.vue"
const List = resolve => require(["@/components/admin/order/_list.vue"], resolve)

export default {
    components: {
        Sidebar,
        List,
    },
    data: function() {
        return {
            activePage: {},
            userStateList: []
        }
    },
    computed: {
        componentType() {
            switch(this.$route.params.type) {
                case 'list':
                    return List;
            }
        },
        sidebarList() {
            let list = [];

            switch (this.$route.params.type) {
                case 'list':
                    list = [
                        {
                            name: '全部訂單',
                            url: {
                                type: 'list',
                                subPage: 'all'
                            },
                            isShow: true
                        },
                        {
                            name: '付款成功',
                            url: {
                                type: 'list',
                                subPage: 'finished'
                            },
                            isShow: true
                        },
                        {
                            name: '付款失敗',
                            url: {
                                type: 'list',
                                subPage: 'failed'
                            },
                            isShow: true
                        },
                        {
                            name: '取消訂單',
                            url: {
                                type: 'list',
                                subPage: 'cancellation'
                            },
                            isShow: true
                        },
                    ]
                    break;
            }

            list.forEach(el => {
                el.isActive = el.url.subPage == this.$route.params.subPage;
                if (el.isActive) {
                    this.activePage = el;
                }
            });
            list = list.filter(item => item.isShow);

            return list;
        },
    },
    created: function() {
        // 只有admin可以進入這頁，其餘權限會被導到course頁面
        if (this.$store.state.common.userInfo.permission != 'admin') {
            this.$router.push('/admin/course/list/all');
        }

        if (this.$store.state.common.userInfo.adminPermission.length == 0) {
            this.$store.commit('common/setHeaderActiveLink', 'adminPersonalOrder');
        }
        else {
            this.$store.commit('common/setHeaderActiveLink', 'adminOrder');
            this.$store.commit('common/setHeaderChildrenActiveLink', 'adminPersonalOrder');
        }

        if (this.$route.params.type == 'list') {
            this.setOrderStateList();
        }
    },
    methods: {
        setOrderStateList() {
            this.sidebarList.forEach(el => {
                this.userStateList.push({
                    name: el.name,
                    stateNum: el.stateNum
                });
            });
        }
    }
}
