import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/scss/index.scss'
// common
import Dialogue from "@/components/common/dialogue.vue"

export default {
    props: ['courseBasicInfo'],
    components: {
        DatePicker,
        Dialogue
    },
    data: function() {
        return {
            activeChangeStateDialogue: '',
            targetAction: '',
            isPublishNow: '0',
            newPublishTime: null,
            oldPublishTime: null,
            isStopSellingScheduled: '0',
            newStopSellingTime: null,
            oldStopSellingTime: null,
            inputTimeErrorMsg: {
                publishTime: '',
                stopSellingTime: ''
            },

            isPostingApi: {
                updateCourse: false,  // 更新課程
                checkEditedItem: false,  // 送審課程前檢查必填項目是否填寫完整
            }
        }
    },
    computed: {
        publishOptList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '排程'
                },
                {
                    value: '1',
                    text: '立即'
                }
            ]
            return opts;
        },
        stopSellingOptList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '無'
                },
                {
                    value: '1',
                    text: '排程'
                }
            ]
            return opts;
        },
        updateCourseData: {
            get() {
                return this.$store.state.adminCourse.updateCourseData;
            },
            set(value) {
                this.$store.commit('adminCourse/setUpdateCourseData', value);
            }
        },
        isInputFormatError() {
            return this.$store.state.adminCourse.isInputFormatError;
        },
        isNotAllowToUpdateData() {
            return this.$_.isEmpty(this.$_.omit(this.updateCourseData, ['saveType'])) ||
                    this.isInputFormatError;
        },
        isNotAllowToChangeState() {
            return !this.$_.isEmpty(this.$_.omit(this.updateCourseData, ['saveType'])) ||
                    this.isInputFormatError;
        },
        isDisableScheduleTime() {
            // 選擇 '排程上架', 但排程日期為空
            if (this.courseBasicInfo.state === '2' && this.isPublishNow === '0' && !this.newPublishTime) {
                return true;
            }
            // 選擇 '排程停售', 但排程日期為空
            if (this.isStopSellingScheduled === '1' && !this.newStopSellingTime) {
                return true;
            }
            // 上架/停售的排程日期未修改過
            if ((this.isPublishNow === '0' && this.newPublishTime === this.oldPublishTime) &&
                (this.newStopSellingTime === this.oldStopSellingTime)) {
                return true;
            }

            return false;
        }
    },
    mounted: function() {
        // 一開啟上架/停售設定彈窗時
        $('#publishCourseSettingsDialogue').on('show.bs.modal', () => {
            // 上架
            this.isPublishNow = '0';
            this.newPublishTime = this.courseBasicInfo.publishTime;
            this.oldPublishTime = this.courseBasicInfo.publishTime;
            // 停售
            this.isStopSellingScheduled = this.courseBasicInfo.stopSellingTime ? '1' : '0';
            this.newStopSellingTime = this.courseBasicInfo.stopSellingTime;
            this.oldStopSellingTime = this.courseBasicInfo.stopSellingTime;

            this.inputTimeErrorMsg = this.$_.mapValues(this.inputTimeErrorMsg, () => '');
        });
    },
    methods: {
        updateCourse() {
            if (!this.$route.params.id ||
                (this.updateCourseData && this.updateCourseData.saveType === undefined) ||
                this.isNotAllowToUpdateData ||
                this.isPostingApi.updateCourse) {
                return;
            }

            // 依據儲存類型, 指定不同 api url 與 params
            let apiUrl = '', params = null;
            let currentSaveType = this.updateCourseData.saveType;

            this.updateCourseData = this.$_.omit(this.updateCourseData, ['saveType']);
            this.updateCourseData.courseId = this.$route.params.id;

            switch (currentSaveType) {
                // 課程資料
                case 'info':
                    apiUrl = '/admin_api/course/update_course_info';
                    params = this.getUpdateInfoParams();
                    break;
                // 課程文章
                case 'articles':
                    apiUrl = '/admin_api/course/update_categories';
                    params = this.getRawDataParams();
                    break;
                // 銷售方案
                case 'sales':
                    apiUrl = '/admin_api/course/update_course_price';
                    params = this.getUpdatePriceParams();
                    break;
                // 負責編輯
                case 'editors':
                    apiUrl = '/admin_api/course/update_course_editors';
                    params = this.getRawDataParams();
                    break;
            }

            if (!apiUrl || !params) {
                return;
            }

            this.isPostingApi.updateCourse = true;

            this.$httpRequest.post(apiUrl, params)
                .then(response => {
                    this.isPostingApi.updateCourse = false;

                    if (response.data.state == 'OK') {
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });

                        this.updateCourseData = null;  // 清空更新資料, 用來 disable 儲存按鈕

                        // 更新儲存"負責編輯"頁面資料後, 需判斷是否刪除自己以做對應處理
                        if (currentSaveType == 'editors') {
                            let result = response.data.result;
                            if (result) {
                                this.$store.commit('adminCourse/setIsSelfDeletedFromEditors', result.is_self_deleted);
                            }
                        }
                        // 觸發更新成功, 以讓右邊頁面接收後做對應處理
                        this.$store.commit('adminCourse/setIsFinishUpdatingData', true);
                    }

                    if (response.data.state == 'ERROR') {
                        // 如果因為後端錯誤造成儲存失敗, 則繼續 enable 儲存按鈕
                        this.updateCourseData.saveType = currentSaveType;
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        getRawDataParams() {
            let params = null;
            params = this.updateCourseData;
            return params;
        },
        // form data
        getUpdateInfoParams() {  // 課程資料
            let params = new FormData();

            let excludeKeys = ['cover', 'deleteCoverFile'];  // 排除不需要的 keys
            let updateData = this.$_.omit(this.updateCourseData, excludeKeys);

            updateData.deleteFiles = [];
            if ('deleteCoverFile' in this.updateCourseData && this.updateCourseData.deleteCoverFile) {
                updateData.deleteFiles.push('cover');
            }
            if (!updateData.deleteFiles.length) {  // 如果沒有要刪除的檔案, 則不需帶 deleteFiles 這個 key
                updateData = this.$_.omit(updateData, ['deleteFiles']);
            }

            params.append('courseInfo', JSON.stringify(updateData));

            // 檔案類型
            // 基本資料 - 課程封面
            if ('cover' in this.updateCourseData && this.updateCourseData.cover.file) {
                params.append('cover', this.updateCourseData.cover.file);
            }

            return params;
        },
        getUpdatePriceParams() {  // 銷售方案
            let params = new FormData();
            params.append('info', JSON.stringify(this.updateCourseData));
            return params;
        },

        disablePublishDate(date) {
            // disable小於今天以前的日期與大於有設定的停售時間
            return date < new Date().setHours(0, 0, 0, 0) ||
                    (this.newStopSellingTime && date.setHours(0, 0, 0, 0) > new Date(this.newStopSellingTime).setHours(0, 0, 0, 0));
        },
        disablePublishTime(date) {
            // disable小於目前時間(時)以前的時間與大於有設定的停售時間(時)
            return date <= new Date() ||
                    (this.newStopSellingTime && date >= new Date(this.newStopSellingTime));
        },
        disableStopSellingDate(date) {
            // disable小於有設定的上架時間
            return date < new Date().setHours(0, 0, 0, 0) ||
                    (this.newPublishTime && date.setHours(0, 0, 0, 0) < new Date(this.newPublishTime).setHours(0, 0, 0, 0));
        },
        disableStopSellingTime(date) {
            // disable小於有設定的上架時間(時)
            return date <= new Date() ||
                    (this.newPublishTime && date <= new Date(this.newPublishTime));
        },

        checkCourseEditedItem(nextState) {
            if (!this.$route.params.id ||
                this.isNotAllowToChangeState ||
                this.isPostingApi.checkEditedItem ||
                !nextState) {
                return;
            }

            let params = {
                courseId: this.$route.params.id
            }

            this.isPostingApi.checkEditedItem = true;

            this.$httpRequest.post('/admin_api/course/check_course_completeness', params)
                .then(response => {
                    this.isPostingApi.checkEditedItem = false;

                    if (response.data.state == 'OK') {
                        this.showChangeStateDialogue(nextState);
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        showChangeStateDialogue(newAction) {
            if (this.isNotAllowToChangeState || !newAction) {
                return;
            }

            this.activeChangeStateDialogue = '';
            this.targetAction = newAction;

            switch (this.targetAction) {
                // { 任何狀態 } -> 編輯中
                case 'edit':
                    this.activeChangeStateDialogue = 'changeToEditingStatePromptDialogue';
                    break;
                // 編輯中 -> 待審核
                case 'audit':
                    this.activeChangeStateDialogue = 'auditCoursePromptDialogue';
                    break;
                // 待審核 -> { 已排程 | 上架中 }
                // 已上架: 修改停售排程時間
                case 'schedule':
                    this.activeChangeStateDialogue = 'publishCourseSettingsDialogue';
                    break;
            }

            if (!this.activeChangeStateDialogue) {
                return;
            }

            $(`#${this.activeChangeStateDialogue}`).modal('show');
        },
        handleChangingCourseState() {
            $('.lms-btn').blur();

            if (this.$parent.isPostingApi.changeState) {
                return;
            }

            // 輸入時間格式檢查
            if (this.targetAction === 'schedule') {
                this.inputTimeErrorMsg = this.$_.mapValues(this.inputTimeErrorMsg, () => '');
                let publishTime = this.$util.datetimeToUnixTimestamp(this.newPublishTime),
                    stopSellingTime = this.$util.datetimeToUnixTimestamp(this.newStopSellingTime);
                // 上架時間或停售時間輸入錯誤
                if (this.$_.isNaN(publishTime) || this.$_.isNaN(stopSellingTime)) {
                    if (this.$_.isNaN(publishTime)) {
                        this.inputTimeErrorMsg.publishTime = '輸入時間錯誤，請重新輸入';
                    }
                    if (this.$_.isNaN(stopSellingTime)) {
                        this.inputTimeErrorMsg.stopSellingTime = '輸入時間錯誤，請重新輸入';
                    }
                    return;
                }
                // 上架時間是否比停售時間晚
                if (publishTime && stopSellingTime && (publishTime >= stopSellingTime)) {
                    this.inputTimeErrorMsg.stopSellingTime = '輸入的停售時間須比上架時間晚';
                    return;
                }
            }

            let options = {
                activeDialogue: this.activeChangeStateDialogue,
                targetAction: this.targetAction,
                isPublishNow: this.isPublishNow,
                publishTime: this.$util.datetimeToUnixTimestamp(this.newPublishTime),
                stopSellingTime: this.$util.datetimeToUnixTimestamp(this.newStopSellingTime)
            }

            this.$parent.changeCourseState(options);
        }
    }
}
