export default {
    props: {
        mediaInfo: {
            required: true,
            // required keys: type (video | image), url, aspectRatio (16-9 | 4-3) {if type == 'image'}
            type: Object
        },
        isDisableScrollBody: {  // 是否在開啟預覽器的狀態下鎖住 body 卷軸
            type: Boolean,
            default: true
        }
    },
    computed: {
        isShowMediaPreviewer: {
            get() {
                return this.$store.state.common.isShowMediaPreviewer;
            },
            set(value) {
                this.$store.commit('common/setIsShowMediaPreviewer', value);
            }
        }
    },
    mounted: function() {
        if (this.isDisableScrollBody) {
            $('body').css({ 'overflow': 'hidden' });
        }
    },
    beforeDestroy: function() {
        if (this.isDisableScrollBody) {
            $('body').css({ 'overflow': '' });
        }
    }
}
