import { render, staticRenderFns } from "./success.vue?vue&type=template&id=3ad12218&scoped=true&"
var script = {}
import style0 from "@/assets/scss/components/feedback/success.scss?vue&type=style&index=0&id=3ad12218&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ad12218",
  null
  
)

export default component.exports