import InfiniteScroll from "vue-infinite-scroll"

export default {
    props: ['tableHeadList', 'allAnnouncementIds'],
    directives: {
        InfiniteScroll
    },
    data: function() {
        return {
            announcementList: [],

            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 10,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢
        }
    },
    watch: {
        newPublishedItem(newValue) {
            if (!this.$_.isEmpty(newValue)) {
                this.announcementList = [newValue].concat(this.announcementList);
                this.newPublishedItem = {};
            }
        },
        deletedPublishedId(newValue) {
            if (!this.$_.isNull(newValue)) {
                this.announcementList = this.$_.remove(this.announcementList, el => {
                    return el.id !== newValue;
                });
                this.deletedPublishedId = null;
            }
        }
    },
    computed: {
        newPublishedItem: {
            get() {
                return this.$store.state.adminPnAnnouncement.newPublishedItem;
            },
            set(value) {
                this.$store.commit('adminPnAnnouncement/setNewPublishedItem', value);
            }
        },
        deletedPublishedId: {
            get() {
                return this.$store.state.adminPnAnnouncement.deletedPublishedId;
            },
            set(value) {
                this.$store.commit('adminPnAnnouncement/setDeletedPublishedId', value);
            }
        }
    },
    created: function() {
        if (this.allAnnouncementIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                this.getAnnouncementList()
                    .then(() => this.busy = false)
                    .catch(error => console.log('catched error: ' + error));
            }
        },
        getAnnouncementList() {
            return new Promise((resolve, reject) => {
                let announcementIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempAnnouncementId = this.allAnnouncementIds[this.loadingIndex];
                    if (!tempAnnouncementId) {
                        this.isGetAllList = true;
                        break;
                    }
                    announcementIds.push(tempAnnouncementId);
                    this.loadingIndex++;
                }

                if (announcementIds.length == 0) {
                    resolve();
                    return;
                }

                let params = {
                    announcementIds: announcementIds
                }

                this.$httpRequest.post('/admin_api/announcement/get_announcement_list_info_by_ids', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                if (!this.$_.isEmpty(result)) {
                                    announcementIds.forEach(id => {
                                        let tempAnnouncement = result[id];
                                        if (tempAnnouncement) {
                                            this.announcementList.push({
                                                id: tempAnnouncement.id,
                                                title: tempAnnouncement.title,
                                                publishStart: tempAnnouncement.start_date,
                                                publishEnd: tempAnnouncement.end_date
                                            });
                                        }
                                    });
                                }

                                resolve();
                            } else {
                                reject("admin/push_notification/announcement/_published_table_list.js: 'getAnnouncementList' get error");
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject("admin/push_notification/announcement/_published_table_list.js: 'getAnnouncementList' get error");
                        }
                    })
                    .catch(error => {
                        console.log('catched error: ' + error);
                        reject("admin/push_notification/announcement/_published_table_list.js: 'getAnnouncementList' get error");
                    });
            });
        }
    }
}
