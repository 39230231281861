// common
import Dialogue from "@/components/common/dialogue.vue"
import InfiniteScroll from "vue-infinite-scroll"

export default {
    props: ['allGroupBuyingIds', 'sidebarList'],
    directives: {
        InfiniteScroll
    },
    components: {
        Dialogue
    },
    data: function() {
        return {
            groupBuyingList: [],
            groupBuyingListIndex: '',
            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢

            // 合購方案詳細資料，courses需要初始值，不然vue找不到參數會錯
            groupBuyingDetail: {},
            groupBuyingId: 0,
            groupBuyingItemList: [],
            viaPaymentNo: '', // via完成訂單邊號
            isOpenCancel: false, // 是否打開取消區
            cancelReason: '',
        }
    },
    computed: {
        tableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "order-no",
                    itemText: "訂單編號",
                    ownClass: "order-no",
                    isShow: true
                },
                {
                    itemName: "buyer-name",
                    itemText: "委託人",
                    ownClass: "buyer-name",
                    isShow: true
                },
                {
                    itemName: "exchange-code",
                    itemText: "序號",
                    ownClass: "exchange-code",
                    isShow: true
                },
                {
                    itemName: "exchange-quantity",
                    itemText: "序號數量",
                    ownClass: "exchange-quantity",
                    isShow: true
                },
                {
                    itemName: "total-amount",
                    itemText: "總金額",
                    ownClass: "total-amount",
                    isShow: true
                },
                {
                    itemName: "dealer-name",
                    itemText: "經手人",
                    ownClass: "dealer-name",
                    isShow: true
                },
                {
                    itemName: "sales-name",
                    itemText: "業務",
                    ownClass: "sales-name",
                    isShow: true
                },
                {
                    itemName: "payment-type",
                    itemText: "付款方式",
                    ownClass: "payment-type",
                    isShow: true
                },
                {
                    itemName: "payment-status",
                    itemText: "付款狀態",
                    ownClass: "payment-status",
                    isShow: true
                },
                {
                    itemName: "is-payment",
                    itemText: "藍星金流",
                    ownClass: "is-payment",
                    isShow: true
                },
                {
                    itemName: "enterdate",
                    itemText: "訂單日期",
                    ownClass: "enterdate",
                    isShow: true
                },
                {
                    itemName: "advanced",
                    itemText: "進階",
                    ownClass: "advanced",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
        courseTableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "id",
                    itemText: "課程ID",
                    ownClass: "course-id",
                    isShow: true
                },
                {
                    itemName: "name",
                    itemText: "課程名稱",
                    ownClass: "course-name",
                    isShow: true
                },
                {
                    itemName: "original-price",
                    itemText: "原價",
                    ownClass: "course-original-price",
                    isShow: true
                },
                {
                    itemName: "price",
                    itemText: "價格",
                    ownClass: "course-price",
                    isShow: true
                },
                {
                    itemName: "count",
                    itemText: "數量",
                    ownClass: "course-count",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
    },
    created: function() {
        if (this.allGroupBuyingIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                this.getGroupBuyingList()
                    .then(() => this.busy = false)
                    .catch(error => console.log('catched error: ' + error));
            }
        },
        getGroupBuyingList() {
            return new Promise((resolve, reject) => {
                let groupBuyingIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempOrderId = this.allGroupBuyingIds[this.loadingIndex];
                    if (!tempOrderId) {
                        this.isGetAllList = true;
                        break;
                    }
                    groupBuyingIds.push(tempOrderId);
                    this.loadingIndex++;
                }

                if (groupBuyingIds.length == 0) {
                    resolve();
                    return;
                }

                let params = {
                    orderIds: groupBuyingIds
                }

                this.$httpRequest.get('/admin_api/order/get_group_buying_list_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                if (!this.$_.isEmpty(result)) {
                                    groupBuyingIds.forEach(id => {
                                        let tempGroupBuying = result[id];
                                        if (tempGroupBuying) {
                                            this.groupBuyingList.push({
                                                id: tempGroupBuying.id,
                                                orderNo: tempGroupBuying.order_no,
                                                status: tempGroupBuying.status,
                                                wordStatus: this.fromatStatus(tempGroupBuying.status),
                                                buyerName: tempGroupBuying.buyer_name,
                                                dealerName: tempGroupBuying.dealer_name,
                                                salesName: tempGroupBuying.sales_name,
                                                remark: tempGroupBuying.remark,
                                                exchangeQuantity: tempGroupBuying.exchange_count,
                                                price: tempGroupBuying.price,
                                                paymentType: tempGroupBuying.payment_type,
                                                wordPaymentType: this.fromatPaymentType(tempGroupBuying.payment_type),
                                                isPayment: tempGroupBuying.is_payment == 1 ? true : false,
                                                bankCode: tempGroupBuying.atm_bank_code,
                                                vAccount: tempGroupBuying.atm_payment_code,
                                                viaPaymentNo: tempGroupBuying.via_payment_no,
                                                exchangedCode: tempGroupBuying.exchanged_code ? true : false,
                                                paymentDeadline: this.$util.unixTimestampToDatetime(tempGroupBuying.payment_deadline, this.$util.getBrowserCurrentTimeZone()),
                                                expired_date: this.$util.unixTimestampToDatetime(tempGroupBuying.expired_date, this.$util.getBrowserCurrentTimeZone()),
                                                finished_date: this.$util.unixTimestampToDatetime(tempGroupBuying.finished_date, this.$util.getBrowserCurrentTimeZone()),
                                                enterdate: this.$util.unixTimestampToDatetime(tempGroupBuying.enterdate, this.$util.getBrowserCurrentTimeZone()),
                                            });
                                        }
                                    });
                                }

                                resolve();
                            } else {
                                reject("admin/course/list/_table_list.js: 'getGroupBuyingList' get error");
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject("admin/course/list/_table_list.js: 'getGroupBuyingList' get error");
                        }
                    })
                    .catch(error => {
                        console.log('catched error: ' + error);
                        reject("admin/course/list/_table_list.js: 'getGroupBuyingList' get error");
                    });
            });
        },
        fromatStatus(status) {
            switch(status) {
                case '0':
                    return '未付款';
                case '1':
                    return '已付款';
                case '2':
                    return '逾期未付';
                case '3':
                    return '付款失敗';
                case '4':
                    return '已取消';
                case '5':
                    return '免付款';
            }
        },
        fromatPaymentType(val) {
            switch(val) {
                case 'credit':
                    return '信用卡';
                case 'atm':
                    return 'ATM轉帳';
                case 'cvs':
                    return '超商代碼';
                case 'barcode':
                    return '超商條碼';
            }
        },
        getExchangeCodeFile(orderNo) {
            let params = {
                orderNo: orderNo
            }
            this.$httpRequest.get('/admin_api/report/get_exchange_code', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let url = response.data.result;
                        let fileLink = document.createElement('a');
                        fileLink.href = url;
                        fileLink.setAttribute('download', 'exchange_code_' + orderNo + '.csv');
                        fileLink.click();
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        showGroupBuyingDetailDialogue(index, item) {
            this.viaPaymentNo = '';
            this.isOpenCancel = false;
            this.cancelReason = '';
            this.groupBuyingListIndex = index;
            this.groupBuyingDetail = item;
            $('#groupBuyingDetailDialogue').modal('show');

            let params = {
                orderNo: item.orderNo
            }

            this.$httpRequest.get('/admin_api/order/get_order_items', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.groupBuyingItemList = result.order_items;
                        } else {
                            console.log("admin/group_buying/_table_list.js: 'getOrderItems' get error");
                        }
                    }
                    if (response.data.state == 'ERROR') {
                        console.log("admin/group_buying/_table_list.js: 'getOrderItems' get error");
                    }
                })
                .catch(function (error) {
                    console.log('catched error: ' + error);
                });
        },
        confirm_order() { // 不走藍星金流付款，需透過威盛付款編號的填寫，來完成訂單
            return new Promise((resolve, reject) => {

                let params = {
                    orderNo: this.groupBuyingDetail.orderNo,
                    viaPaymentNo: this.viaPaymentNo,
                    exchangeCount: this.groupBuyingDetail.exchangeQuantity,
                }

                this.$httpRequest.post('/admin_api/order/finish_group_order_by_admin', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                if (result.success) {
                                    this.groupBuyingDetail.status = 1;
                                    this.$set(this.groupBuyingList[this.groupBuyingListIndex], 'status', 1);
                                    this.$set(this.groupBuyingList[this.groupBuyingListIndex], 'wordStatus', this.fromatStatus('1'));
                                    this.$set(this.groupBuyingList[this.groupBuyingListIndex], 'exchangedCode', true);
                                    this.$set(this.groupBuyingList[this.groupBuyingListIndex], 'viaPaymentNo', this.viaPaymentNo);
                                    this.groupBuyingListIndex = '';
                                    this.$store.dispatch('common/setAlert', { msg: result.msg, status: 'success' });
                                }
                                else {
                                    this.viaPaymentNo = '';
                                    this.$store.dispatch('common/setAlert', { msg: result.msg, status: 'danger' });
                                }
                                $('#groupBuyingDetailDialogue').modal('hide');
                                resolve();
                            } else {
                                reject("admin/order/_table_list.js: 'confirm_order' get error");
                            }
                            $('#showOrderDetailDialogue').modal('hide');
                        }
                        if (response.data.state == 'ERROR') {
                            reject("admin/order/_table_list.js: 'confirm_order' get error");
                        }
                    })
                    .catch(function (error) {
                        console.log('catched error: ' + error);
                        reject("admin/order/_table_list.js: 'confirm_order' get error");
                    });
            });
        },
        cancel_order() {
            return new Promise((resolve, reject) => {

                let params = {
                    orderNo: this.groupBuyingDetail.orderNo,
                    cancelReason: this.cancelReason
                }

                this.$httpRequest.post('/admin_api/order/cancel_order_by_admin', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                if (result.success) {
                                    this.groupBuyingDetail.status = 4;
                                    this.$set(this.groupBuyingList[this.groupBuyingListIndex], 'status', 4);
                                    this.$set(this.groupBuyingList[this.groupBuyingListIndex], 'wordStatus', this.fromatStatus('4'));
                                    if (!result.exchanged_codes) { // 因為沒有人兌換，才可以把下載連結移除
                                        this.$set(this.groupBuyingList[this.groupBuyingListIndex], 'exchangedCode', false);
                                    }
                                    this.groupBuyingListIndex = '';
                                    this.$store.dispatch('common/setAlert', { msg: '取消成功', status: 'success' });
                                }
                                else {
                                    this.cancelReason = '';
                                    this.$store.dispatch('common/setAlert', { msg: result.msg, status: 'danger' });
                                }
                                $('#groupBuyingDetailDialogue').modal('hide');
                                resolve();
                            } else {
                                reject("admin/order/_table_list.js: 'cancel_order' get error");
                            }
                            $('#showOrderDetailDialogue').modal('hide');
                        }
                        if (response.data.state == 'ERROR') {
                            reject("admin/order/_table_list.js: 'cancel_order' get error");
                        }
                    })
                    .catch(function (error) {
                        console.log('catched error: ' + error);
                        reject("admin/order/_table_list.js: 'cancel_order' get error");
                    });
            });
        }
    }
}
