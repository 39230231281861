import Sidebar from "@/components/admin/user/_sidebar.vue"
const List = resolve => require(["@/components/admin/user/_list.vue"], resolve)
const Edit = resolve => require(["@/components/admin/user/_edit.vue"], resolve)

export default {
    components: {
        Sidebar,
        List,
    },
    data: function() {
        return {
            activePage: {},
            userStateList: []
        }
    },
    computed: {
        componentType() {
            switch(this.$route.params.type) {
                case 'list':
                    return List;
                case 'edit':
                    return Edit;
            }
        },
        sidebarList() {
            let list = [];

            switch (this.$route.params.type) {
                case 'list':
                case 'edit':
                    list = [
                        {
                            name: '全平台使用者',
                            url: {
                                type: 'list',
                                subPage: 'all'
                            },
                            isShow: true
                        },
                        {
                            name: '一般使用者',
                            url: {
                                type: 'list',
                                subPage: 'general'
                            },
                            isShow: true
                        },
                        {
                            name: '開課者',
                            url: {
                                type: 'list',
                                subPage: 'teach'
                            },
                            isShow: true
                        },
                        {
                            name: '管理者',
                            url: {
                                type: 'list',
                                subPage: 'admin'
                            },
                            isShow: true
                        },
                    ]
                    break;
                case 'add':
                    break;
            }

            list.forEach(el => {
                el.isActive = el.url.subPage == this.$route.params.subPage;
                if (el.isActive) {
                    this.activePage = el;
                }
            });
            list = list.filter(item => item.isShow);

            return list;
        },
    },
    created: function() {
        // 只有admin可以進入這頁，其餘權限會被導到course頁面
        if (this.$store.state.common.userInfo.permission != 'admin') {
            this.$router.push('/admin/course/list/all');
        }

        this.$store.commit('common/setHeaderActiveLink', 'adminUser');

        if (this.$route.params.type == 'list') {
            this.setUserStateList();
        }
    },
    methods: {
        setUserStateList() {
            this.sidebarList.forEach(el => {
                this.userStateList.push({
                    name: el.name,
                    stateNum: el.stateNum
                });
            });
        }
    }
}
